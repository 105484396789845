<template>
	<nav class="apps">
		<Account />
		<router-link
			v-for="app in appList"
			:key="app.name"
			class="app material-symbols"
			:to="`/${app.path || app.name}`"
		>
			{{ app.icon }}
		</router-link>
	</nav>
</template>

<script>
import Account from '../components/Account.vue' ;
import AppList from '@/stores/AppList.js' ;

export default {
	components: {
		Account
	} ,
	data() {
		return {
			appList: AppList().$state.filter( app => {
				return ! ( typeof app.sidebar !== 'undefined' && ! app.sidebar ) ;
			} )
		} ;
	}
} ;
</script>

<style scoped>
.apps {
	background-color: var(--color-background);
	color: var(--color-text);
	box-shadow: 0px 0px 8px 4px #00000050;

	a {
		padding: 0.4em;
		font-size: 2em;

		&.router-link-active {
			background-color: var(--color-accent);
			color: var(--color-background);
		}
		&:hover {
			background-color: var(--color-focus);
			color: var(--color-background);
		}
	}
}
</style>