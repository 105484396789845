<template>
	<div class="breadcrumb">
		<router-link
			v-for="entry in navigation.entries"
			:key="entry.path"
			:to="entry.path || {}"
			class="link"
			:class="{ current: entry.selected }"
		>
			<span v-if="entry.icon" class="material-symbols">{{ entry.icon }}</span>
			<span>{{ entry.title }}</span>

			<span v-if="entry.number"> | {{ entry.number }}</span>
		</router-link>
	</div>
</template>

<script>
import Navigation from '@/stores/Navigation.js' ;

export default {
	data: function() {
		return {
			navigation: Navigation()
		} ;
	}
} ;
</script>

<style scoped>
.breadcrumb {
	animation: enter-top 300ms ease;
	display: grid;
	grid-auto-flow: column;
	justify-content: start;
	align-items: center;
	font-size: 1.6em;
	background-color: var(--color-background);
	box-shadow: 0 0 6px 2px #00000050;
	position: sticky;
	top: 0;
	z-index: 1;
	overflow: hidden;
}

.breadcrumb .link .material-icons {
	margin: 0 0.4em;
}

.breadcrumb .link {
	outline: none;
	position: relative;
	text-decoration: none;
	transition: background 0.2s linear;
	font-size: 0.8em;
	display: inline-block;
	padding: 0.5em 1em;
	cursor: pointer;

}

.breadcrumb .link>* {
	margin-left: 0.5em;
}

.breadcrumb .link:hover:after,
.breadcrumb .link.current:after,
.breadcrumb .link:hover,
.breadcrumb .link.current {
	background-color: var(--color-accent);
	color: var(--color-background);
}

.breadcrumb .link:after,
.breadcrumb .link:before {
	background: var(--color-background);
	color: var(--color-text);
	bottom: 0;
	/* clip-path: polygon(50% 50%, -50% -50%, 0 100%); */
	clip-path: polygon(0 0, 0% 100%, 100% 0);
	content: "";
	left: 100%;
	position: absolute;
	top: 0;
	transition: background 0.2s linear;
	width: 1em;
	z-index: 1;
}

.breadcrumb .link:last-child {
	border-right: none;
}

.breadcrumb .link.is-active {
	background: var(--color-accent);
}

@media (max-width: 1350px) {
	.breadcrumb .link>*{
		margin-left:0px;
	}
	.breadcrumb .link::before,
	.breadcrumb .link::after {
		content: "";
		width: 1px !important;

	}
	.breadcrumb span {
		display:none;
	}
	.breadcrumb span.material-symbols {
		display:inline-block;
	}
	.breadcrumb a:first-child span {
		display:inline-block;
	}

}


</style>