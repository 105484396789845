import { defineStore } from 'pinia' ;

var storageKey = 'Navigation' ;
var navigationData = {} ;
try {
	navigationData = JSON.parse( localStorage.getItem( storageKey ) ) || {} ;
}
catch { /* empty */ }
navigationData.entries ??= [] ;

export default defineStore( 'Navigation' , {
	state: () => ( {
		entries: navigationData.entries
	} ) ,
	getters: {
		getIndexSelected: function() {
			return this.entries.findIndex( entry => entry.selected ) ;
		} ,
		back: function() {
			return this.entries[Math.max( 0 , this.getIndexSelected - 1 )].path ;
		} ,
		forward: function() {
			return this.entries[Math.min( this.entries.length , this.getIndexSelected + 1 )].path ;
		}
	} ,
	actions: {
		addEntry: function( newEntry ) {
			newEntry.parent = newEntry.path.slice( 1 ).split( '/' ).slice( 0 , - 1 ).join( '/' ) ;
			newEntry.selected = true ;

			var replace = false ;
			for( let [index , entry] of this.entries.entries() ) {
				if ( entry.path === newEntry.path ) {
					Object.assign( entry , newEntry ) ;
					replace = true ;
					continue ;
				}
				else if ( entry.parent === newEntry.parent ) {
					this.entries.splice( index , Infinity , newEntry ) ;
					replace = true ;
					break ;
				}
				else {
					entry.selected = false ;
				}
			}

			if ( ! newEntry.path.includes( 'NEW' ) ) {
				this.entries = this.entries.filter( entry => ! entry.path.includes( 'NEW' ) ) ;
			}

			if ( ! replace ) {
				this.entries.push( newEntry ) ;
			}
			this.save() ;
		} ,
		clear: function() {
			this.entries = [] ;
		} ,
		save: function() {
			localStorage.setItem( storageKey , JSON.stringify( this.$state ) ) ;
		}
	}
} ) ;
