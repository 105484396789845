<template>
	<div class="home">
		<router-link v-for="app in appList" :key="app.name" class="app" :to="app.path || app.name">
			<div class="material-symbols" v-text="app.icon" />
			<div v-text="app.name" />
		</router-link>
	</div>
</template>

<script>
import AppList from '@/stores/AppList.js' ;
import Navigation from '@/stores/Navigation.js' ;

export default {
	data() {
		return {
			navigation: Navigation() ,
			appList: AppList().$state.filter( app => {
				return ! ( typeof app.dashboard !== 'undefined' && ! app.dashboard ) ;
			} )
		} ;
	} ,
	mounted: function() {
		this.navigation.clear() ;
	}
} ;
</script>

<style scoped>
.home {
	min-height: 100%;

	display: grid;
	grid-template-columns: repeat(auto-fill, 10em);
	grid-gap:2em;
	justify-content: center;
	align-content: center;

	.app {
		text-align: center;
		font-size: 0.9em;

		div{
			display:inline-block;
			/*background-color: rgb(from var(--color-background) r g b / 0.5);
			border-radius:0.5em;
			padding:0 0.3em ;*/
			text-shadow:1px 0px 1px var(--color-background);


		}
		.material-symbols {
			display:block;
			width:1.1em;
			font-size: 3em;
			border:solid 1px var(--color-accent);
			padding:0.5em;
			margin: 0.2em auto;
			border-radius:0.2em;
			background-color: var(--color-background);
			border: solid 1px #00000050;
			box-shadow: 0px 3px 5px 2px #00000050;
			text-shadow:unset;
			transition: box-shadow 200ms, transform 200ms;
		}

		&:hover {
			.material-symbols {


				color:var(--color-background);
				background-color: var(--color-focus);

				box-shadow: 2px 4px 6px 2px #00000050;
				scale: 1.1;


			}
		}
		&:active {
			.material-symbols {

					/*
				color:var(--color-background);
				background-color: var(--color-accent);
				*/
				box-shadow: 0px 0px 1px 1px #00000050;
				scale: 1;


			}
		}


	}
	@media (max-width: 1000px) {
		padding-bottom: 6em;
	}
}
</style>