<template>
	<h1>{{ document.client?.hid || 'No client' }}</h1>
	<rq-property v-bind="auto('paymentMethod')" />

	<rq-property v-bind="auto('paymentDate')" />

	<rq-property v-bind="auto('invoice')" />
	<rq-property v-bind="auto('amountPaid')" />
	<rq-property v-bind="auto('state')" />
</template>

<script>
import SetupDocument from '@/libRestQuery/vue/setups/Document.js' ;
import mixin from './mixin.js' ;

export default {
	mixins: [mixin] ,
	setup: SetupDocument
} ;
</script>