<template>
	<h1>{{ document.client?.hid || 'No client' }}</h1>
	<rq-property v-bind="auto('includingTaxAmount')" />
	<rq-property v-bind="auto('invoiceNumber')" />
	<rq-property v-bind="auto('invoiceDate')" />
	<rq-property v-bind="auto('state')" />
</template>

<script>
import SetupDocument from '@/libRestQuery/vue/setups/Document.js' ;
import mixin from './mixin.js' ;

export default {
	mixins: [mixin] ,
	setup: SetupDocument
} ;
</script>