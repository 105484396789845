<template>
	<div v-if="ready && workingDocument" class="Document">
		<slot :schema="schema" :document="document" :working-document="workingDocument">
			<Property
				v-for="property in schema.editables"
				:key="property.name"
				v-model="workingDocument[property.name]"
				:original-value="document[property.name]"
				:label="true"
				:property="property"
				:edit="edit"
			/>
		</slot>
	</div>
	<div v-else>
		<slot name="loading">
			NOT READY
		</slot>
	</div>
</template>

<script>
import SetupDocument from '@/libRestQuery/vue/setups/Document.js' ;
import Property from './Property.vue' ;

export default {
	components: {
		Property
	} ,
	props: {
		restQuery: {
			type: Object ,
			required: true
		} ,
		edit: {
			type: Boolean ,
			default: false
		}
	} ,
	setup: SetupDocument
} ;
</script>


<style scoped>
.property {
	padding: 1em 0;
	border-bottom: 1px solid #999;
}
.name {
	text-transform: uppercase;

}
.value {
	color: #FF00BF;
	padding: 0.4em;
}
.link:hover {

	cursor: pointer;
}
</style>