<template>
	<section v-if="schema&&document" class="locked">
		<header>{{ i18n('title.metas') }}</header>
		<div class="grid-align">
			<rq-property :label="true" :edit="false" :property="schema.properties.creationDate" :original-value="document.creationDate" />
			<rq-property :label="true" :edit="false" :property="schema.properties.creationUser" :original-value="document.creationUser" />
			<rq-property :label="true" :edit="false" :property="schema.properties.modificationDate" :original-value="document.modificationDate" />
			<rq-property :label="true" :edit="false" :property="schema.properties.modificationUser" :original-value="document.modificationUser" />
		</div>
	</section>
</template>

<script>

export default {
	inject: {
		schema: {
			from: 'schema'
		} ,
		document: {
			from: 'document'
		}
	}
} ;
</script>