import strings from '@/libRestQuery/js/utils/strings.js' ;
import MetaBlock from './shared/MetaBlock.vue' ;

export default {
	components: {
		MetaBlock
	} ,
	props: {
		document: {
			type: Object ,
			default: () => {}
		} ,
		schema: {
			type: Object ,
			required: true
		} ,
		modelValue: {
			type: Object ,
			required: true
		} ,
		edit: {
			type: Boolean ,
			default: false
		}
	} ,
	emits: ['update:modelValue'] ,
	computed: {
		value: {
			get() {
				return this.edit ? this.modelValue : this.document ;
			} ,
			set( value ) {
				if ( ! this.edit ) {
					console.log( 'WARNING: Component should not emit values' , value ) ;
					alert( 'WARNING: Component should not emit values' ) ;
					return ;
				}
				this.$emit( 'update:modelValue' , value ) ;
			}
		} ,
		isNewDocument: function() {
			return !! this.document?._id ;
		}
	} ,
	methods: {
		/*
		// FIXME: probably remove
		copyValue: function( propertyName ) {
			navigator.clipboard.writeText( this.document?.[propertyName] ) ;
			console.log( 'value  copied' ) ;
		} ,
		 */
		auto: function( propertyName , options = {} ) {
			if ( ! this.schema.properties[propertyName] ) alert( `Unknown Property: ${propertyName}` ) ;

			return {
				property: this.schema.properties[propertyName] ,
				label: typeof options.label !== 'undefined' ? options.label : true ,
				edit: typeof options.edit !== 'undefined' ? options.edit : this.edit ,
				originalValue: this.document?.[propertyName] ,
				placeholder: this.schema.properties[propertyName].required ? 'Champ obligatoire' : 'Champ facultatif'
			} ;
		} ,
		createComment: function( type = 'generic' ) {
			if ( ! this.document ) return {} ;
			return {
				params: {
					collection: 'Notes' ,
					document: 'NEW'
				} ,
				query: {
					method: 'EDIT' ,
					baseDocument: JSON.stringify( {
						document: {
							_id: this.document._id ,
							_collection: this.document._collection
						} ,
						type: type
					} )
				}
			} ;
		} ,
		createFrom: function( collection ) {
			if ( ! this.document ) return {} ;
			return {
				params: {
					collection: strings.capitalize( collection ) ,
					document: 'NEW'
				} ,
				query: {
					method: 'EDIT' ,
					baseDocument: JSON.stringify( { client: this.document._id } )
				}
			} ;
		}
	}
} ;