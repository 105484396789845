<template>
	<h1>{{ document.name }}</h1>
	<rq-property v-bind="auto('season')" />
	<rq-property v-bind="auto('year')" />
	<rq-property v-bind="auto('rows')" />
</template>

<script>
import SetupDocument from '@/libRestQuery/vue/setups/Document.js' ;
import mixin from './mixin.js' ;

export default {
	mixins: [mixin] ,
	setup: SetupDocument
} ;
</script>