import { createApp } from 'vue' ;
import { createPinia } from 'pinia' ;

import App from '@/App.vue' ;
import router from './router/index.js' ;

import restQuery from '@/restQuery/index.js' ;
import '@/fonts/loader.js' ;

const app = createApp( App ) ;

app.use( createPinia() ) ;
app.use( router ) ;

import Debug from '@/libRestQuery/vue/components/Debug.vue' ;

import RqCollection from '@/libRestQuery/vue/components/Collection.vue' ;
import RqDocument from '@/libRestQuery/vue/components/Document.vue' ;
import RqProperty from '@/libRestQuery/vue/components/Property.vue' ;
import RqPropertyFilter from '@/libRestQuery/vue/components/PropertyFilter.vue' ;

import IconText from '@/components/Icon-text.vue' ;
import ButtonIcon from '@/components/Button-icon.vue' ;

app.component( 'Debug' , Debug ) ;

app.component( 'RqCollection' , RqCollection ) ;
app.component( 'RqDocument' , RqDocument ) ;
app.component( 'RqProperty' , RqProperty ) ;
app.component( 'RqPropertyFilter' , RqPropertyFilter ) ;

app.component( 'IconText' , IconText ) ;
app.component( 'ButtonIcon' , ButtonIcon ) ;

import importGlobToObject from '@/libRestQuery/js/utils/importGlobToObject.js' ;
var i18n = importGlobToObject( import.meta.glob( '@/i18n/ui/*.json' , { eager: true } ) ) ;
restQuery.i18n.addDictionnaries( i18n , 'ui' ) ;

app.mixin( {
	methods: {
		i18n: function( str ) {
			return restQuery.i18n.get( `ui.${str}` ) ;
		} ,
		debug: function() {
			document.dispatchEvent( new Event( 'debug' ) ) ;
		}
	}
} ) ;


// app.directive( 'grid-area' , ( el , binding ) => {
// 	el.style.gridArea = binding.arg ;
// } ) ;
app.directive( 'grid-area' , {
	created( el , binding ) {
		el.style.gridArea = binding.arg ;
	}
} ) ;

app.mount( '#app' ) ;

export default app ;

