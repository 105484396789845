<template>
	<div class="document">
		<h1>
			{{ i18n('deposits.title') }}
			<rq-document v-if="value.client" v-slot="{document}" class="inline" :rest-query="{collection:'Organizations',document:value.client._id||value.client}">
				<span>
					<template v-if="document.familyName">- Famille {{ document.familyName }}</template>
				</span>
			</rq-document>
		</h1>

		<div class="cols2">
			<section v-grid-area:identification>
				<header>{{ i18n('title.identification') }}</header>

				<div class="grid-align">
					<rq-property v-model="value['carousels']" v-bind="auto('carousels')" :default-query="carouselQuery" />
					<rq-property v-model="value['client']" v-bind="auto('client')" />

					<rq-property v-model="value['orderNumber']" v-bind="auto('orderNumber')" placeholder="Automatique" />
					<rq-property v-model="value['orderDate']" v-bind="auto('orderDate')" />

					<rq-property v-model="value['state']" v-bind="auto('state')" />

					<rq-property v-model="value['departureDate']" v-bind="auto('departureDate')" :min="value.orderDate" />
					<rq-property v-model="value['deliveryDate']" v-bind="auto('deliveryDate')" :min="value.orderDate" />
					<rq-property v-model="value['archived']" v-bind="auto('archived')" />
				</div>
			</section>

			<section v-grid-area:address>
				<header>{{ i18n('invoices.adresse') }}</header>
				<div class="grid-align">
					<div v-if="edit" class="property">
						<label>Select address</label>
						<select v-model="selectedAddress">
							<option v-for="(address,index) in addresses" :key="address.type" :value="index">{{ address.type }}</option>
						</select>
					</div>

					<rq-property v-model="value['companyName']" v-bind="auto('companyName')" />
					<rq-property v-model="value['address']" v-bind="auto('address')" />
					<rq-property v-model="value['zipCode']" v-bind="auto('zipCode')" />
					<rq-property v-model="value['city']" v-bind="auto('city')" />
					<rq-property v-model="value['country']" v-bind="auto('country')" />
				</div>
			</section>
			<section v-grid-area:comments>
				<header>{{ i18n('title.comments') }}</header>
				<div>
					<rq-property v-model="value['deliveryComment']" v-bind="auto('deliveryComment')" />
				</div>
			</section>
			<section>
				<header>{{ i18n('deposits.content') }}</header>
				<div>
					<rq-property v-model="value['products']" v-bind="auto('products',{label:false})" />
				</div>
			</section>
		</div>

		<MetaBlock />
	</div>
</template>

<script>
import mixin from './mixin.js' ;
import Collections from '@/libRestQuery/vue/store/Collections/index.js' ;

export default {
	mixins: [mixin] ,

	data: function() {
		return {
			selectedAddress: null ,
			addresses: null ,
			carouselQuery: {
				filters: {
					archived: {
						$eq: false
					}
				}
			}
		} ;
	} ,
	watch: {
		'value.carousel': {
			handler: function() {
				this.updateProducts() ;
			} ,
			deep: true
		} ,
		'value.client': function() { this.updateClient() ; } ,
		'selectedAddress':	function() { this.updateAddress() ; }
	} ,
	mounted: function() {
		this.updateClient() ;
	} ,
	methods: {
		updateClient: function() {
			if ( ! this.edit ) return ;

			if ( ! this.value.client || typeof this.value.client !== 'string' ) return ;

			Collections( 'Organizations' ).restQueryCollection.document( this.value.client ).get()
				.then( document => {
					this.addresses = document.addresses ;
					this.value.companyName = document.companyName ;
				} ) ;
		} ,
		updateAddress: function() {
			var address = this.addresses[this.selectedAddress] ;
			this.value.address = address.address ;
			this.value.zipCode = address.zipCode ;
			this.value.city = address.city ;
			this.value.country = address.country ;
		} ,
		updateProducts: function() {
			if ( ! Array.isArray( this.value.carousel ) || ! this.value.carousel.length ) return ;

			for( let carousel of this.value.carousel ) {
				if ( ! carousel ) continue ;

				Collections( 'Carousels' ).restQueryCollection.document( carousel ).get()
					.then( document => {
						this.value['products'] = document.products
							.filter( product => product.product?._id )
							.map( product => {
								return {
									product: { _id: product.product._id } ,
									quantity: product.quantity
								} ;
							} ) ;
					} ) ;
			}
		}
	}
} ;
</script>

<style scoped>
.document {
	grid-template:
		"identification metas"
		"products products"
		"comments comments" / 1fr 1fr;
}
@media (max-width: 1024px) {
	.document {
		grid-template:
			"identification"
			"metas"
			"products"
			"comments" / 1fr;
	}
}
</style>