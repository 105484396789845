import { createRouter , createWebHistory } from 'vue-router' ;
import strings from '@/libRestQuery/js/utils/strings.js' ;
import Apps from '../apps/Main.vue' ;

const router = createRouter( {
	history: createWebHistory( import.meta.env.BASE_URL ) ,
	scrollBehavior( /* to , from , savedPosition */ ) {
		return {
			el: document.querySelector( '#main' ) ,
			left: 0 ,
			top: 0 ,
			behavior: 'smooth'
		} ;
	} ,
	routes: [
		{
			path: '/' ,
			redirect: '/Home'
		} ,
		{
			path: '/Login' ,
			name: 'Login' ,
			component: Apps ,
			meta: {}
		} ,
		{
			path: '/Home' ,
			name: 'Home' ,
			component: Apps ,
			meta: { requiresAuth: true }
		} ,
		{
			path: '/Infos' ,
			name: 'Infos' ,
			component: Apps ,
			meta: { requiresAuth: true }
		} ,
		{
			path: '/Account' ,
			name: 'Account' ,
			component: Apps ,
			meta: { requiresAuth: true }
		} ,
		{
			path: '/:collection/:document?' ,
			name: 'Collection' ,
			component: Apps ,
			meta: { requiresAuth: true }
		}
	]
} ) ;

// Maybe remove this code or at least make it check
// if app name is not capitalized and issue a warning
router.beforeEach( ( to /*from*/ ) => {
	if ( to.params?.collection && strings.isLowerCase( to.params.collection[0] ) ) {
		console.warn( 'Router.beforeEach interception! CollectionName not capitalized:' , to.params.collection ) ;
		to.params.collection = strings.capitalize( to.params.collection ) ;
		return to ;
	}
	return null ;
} ) ;

export default router ;