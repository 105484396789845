import { defineStore } from 'pinia' ;
import restQuery from '@/restQuery/index.js' ;

export default defineStore( 'AppList' , {
	getters: {
		restQueryAccount: () => restQuery.account
	} ,
	state: () => (
		[
			{
				name: 'Home' ,
				icon: 'home' ,
				dashboard: false
			} ,
			{
				path: 'Users?.archived.$eq=false&limit=30&sort.lastVisit=-1' ,
				name: 'Utilisateurs' ,
				icon: 'person' ,
				sidebar: false
			} ,
			{
				path: 'Products?.archived.$eq=false&limit=30&sort.hid=1' ,
				name: 'Produits' ,
				icon: 'diamond'
			} ,
			{
				path: 'Carousels?.archived.$eq=false&limit=30&sort.year=-1' ,
				name: 'Présentoires' ,
				icon: 'inventory' ,
				sidebar: false
			} ,
			{
				path: 'Organizations?.archived.$eq=false&limit=30&sort.clientCode=-1' ,
				name: 'Organisations' ,
				icon: 'corporate_fare'
			} ,
			{
				path: 'Deposits?.archived.$eq=false&state.$in=[delivered]&limit=30&sort.hid=1' ,
				name: 'Dépôts' ,
				// icon: 'package_2'
				icon: 'place_item'
			} ,
			{
				path: 'Deposits?.archived.$eq=false&state.$in=[preparing]&limit=30&sort.hid=1' ,
				name: 'Préparations' ,
				icon: 'matter' ,
				sidebar: false
			} ,

			{
				path: 'Returns?.archived.$eq=false&.state.$in=[requested]&state.$in=[requested]&limit=30&sort.collectingWeek=1' ,
				name: 'Retours' ,
				icon: 'local_shipping'
			} ,
			{
				path: 'Invoices?.archived.$eq=false&limit=30&sort.invoiceDate=-1' ,
				name: 'Factures' ,
				icon: 'request_quote'
			} ,
			{
				path: 'Payments?.archived.$eq=false&limit=30&sort.paymentDate=-1' ,
				name: 'Paiements' ,
				icon: 'credit_score' ,
				sidebar: false
			} ,
			{
				path: 'Tags?.archived.$eq=false&limit=30&sort.hid=-1' ,
				name: 'Étiquettes' ,
				icon: 'Sell' ,
				sidebar: false
			} ,
			{
				path: `Notes?.archived.$eq=false&.user.$in=[${useRestqueryAccount().value.userId}]&.dueDate.$exists=true` ,
				name: 'Notes' ,
				icon: 'calendar_month'
			} ,
			{
				name: 'Statistics' ,
				icon: 'bar_chart'
			} ,
			{
				name: 'Tests' ,
				icon: 'bug_report' ,
				sidebar: false
			} ,
			{
				name: 'Infos' ,
				icon: 'help' ,
				sidebar: false
			} ,
			{
				name: 'Compte' ,
				icon: 'settings' ,
				sidebar: false
			}
		]
	)
} ) ;

import { ref } from 'vue' ;
function useRestqueryAccount() {
	const session = ref( null ) ;
	session.value = restQuery.account.session ;
	return session ;
}