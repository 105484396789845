<template>
	<div v-if="lastPageNumber>0" class="Pages">
		<span :class="{invisible:isFirst}" class="page action first" @click="first()">&#9198;</span>
		<span :class="{invisible:isFirst}" class="page action previous" @click="before()">&#9204;</span>

		<span
			v-for="page in pages"
			:key="page"
			:class="{ selected: page === currentPage }"
			class="page"
			@click="go(page)"
		>
			{{ page +1 }}
		</span>

		<span :class="{invisible:isLast}" class="page action next" @click="next()">&#9205;</span>
		<span :class="{invisible:isLast}" class="page action last" @click="last()">&#9197;</span>
	</div>
</template>

<script>
export default {
	props: {
		collectionMeta: {
			type: Object ,
			default: () => {}
		} ,
		modelValue: {
			type: Object ,
			required: true
		} ,
		maxVisiblePages: {
			type: Number ,
			default: 11
		}
	} ,
	emits: ['update:modelValue'] ,
	computed: {
		query: {
			get() {
				return this.modelValue ;
			} ,
			set( query ) {
				this.$emit( 'update:modelValue' , query ) ;
			}
		} ,
		isFirst: function() {
			return this.currentPage === 0 ;
		} ,
		isLast: function() {
			return this.currentPage === this.lastPageNumber ;
		} ,
		currentPage: function() {
			return ( this.query.skip || 0 ) / this.query.limit ;
		} ,

		pages: function() {
			var half = Math.round( this.maxVisiblePages / 2 ) ;
			var current = this.currentPage ;

			let minLeft = Math.max( 0 , current - half ) ;
			let maxLeft = Math.min( minLeft + this.maxVisiblePages , this.lastPageNumber + 1 ) ;

			let maxRight = Math.min( this.lastPageNumber + 1 , current + half ) ;
			let minRight = Math.max( maxRight - this.maxVisiblePages , 0 ) ;

			let leftLength = Math.abs( minLeft - maxLeft ) ;
			let rightLength = Math.abs( minRight - maxRight ) ;

			if ( rightLength >= leftLength ) {
				minLeft = minRight ;
				maxLeft = maxRight ;
			}

			var pages = Array.from( Array( this.lastPageNumber + 1 ).keys() ) ;
			return pages.slice( minLeft , maxLeft ) ;
		} ,
		lastPageNumber: function() {
			return this.collectionMeta.count && this.query.limit && Math.floor( this.collectionMeta.count / this.query.limit ) || 0 ;
		}
	} ,
	methods: {
		go: function( page ) {
			this.query.skip = page * this.query.limit ;
		} ,

		first: function() { this.go( 0 ) ; } ,
		last: function() { this.go( this.lastPageNumber ) ; } ,
		before: function() { this.go( this.currentPage - 1 ) ; } ,
		next: function() { this.go( this.currentPage + 1 ) ; }
	}
} ;
</script>