<template>
	<div class="document">
		<h1 v-text="title" />

		<rq-property
			v-for="property in properties"
			:key="property.name"
			v-model="value[property.name]"
			v-bind="auto(property.name)"
		/>
	</div>
</template>

<script>
import mixin from './mixin.js' ;

export default {
	mixins: [mixin] ,
	computed: {
		properties: function() {
			return this.edit ? this.schema.editables : this.schema.contents ;
		} ,
		title: function() {
			return this.i18n( this.edit ? 'title.documentForm' : 'title.documentRead' ) ;
		}
	}
} ;
</script>


<style scoped>
.property {
	display: flex;
}
.property :deep(.name) {
	flex-shrink: 0;
	white-space: nowrap;
	flex-basis: 20%;
}

</style>