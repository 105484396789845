<template>
	<div v-if="ready" class="value backLink">
		<div
			v-for="document in collection"
			:key="document._id"
		>
			{{ document.note }}
		</div>
	</div>
</template>

<script>
import SetupCollection from '@/libRestQuery/vue/setups/Collection.js' ;

import { inject } from 'vue' ;

export default {
	props: {
		type: {
			type: String ,
			required: true
		}
	} ,
	setup( props , context ) {
		var originalDocument = inject( 'document' ) ;
		var id = `${originalDocument.value?._id}@organizations` ;

		return SetupCollection( props , context , {
			collection: 'Notes' ,
			query: {
				filters: {
					type: {
						$eq: props.type
					} ,
					document: {
						'$in': [id]
					}
				}
			} ,
			queryStats: false
		} ) ;
	}
} ;
</script>