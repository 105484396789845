<template>
	<div class="value embedded">
		<header>
			<div v-for="property in propertyList" :key="property.name" class="property">
				{{ property.localName }}
			</div>
		</header>
		<div v-for="(document,index) in value" :key="document" class="document">
			<rq-property
				v-for="property in propertyList"
				:key="property.name"
				v-model="value[index][property.name]"
				:original-value="originalValue?.[index]?.[property.name]"
				:edit="true"
				:property="property"
			/>

			<div class="actions">
				<span class="material-symbols action moveUp" @click="moveUp(index)">arrow_upward</span>
				<span class="material-symbols action moveDown" @click="moveDown(index)">arrow_downward</span>
				<span class="material-symbols action remove" @click="remove(index)">delete</span>
			</div>
		</div>
		<!-- <div class="document">
			<input
				v-for="property in propertyList"
				:key="property.name"
				class="property"
				@input="add()"
			/>
		</div> -->

		<div class="action add" @click.prevent="add">
			<span class="material-symbols">add</span>Ajouter
		</div>
	</div>
</template>

<script>
import mixin from './mixin' ;

export default {
	mixins: [mixin] ,
	inject: {
		originalSchema: {
			from: 'schema'
		}
	} ,
	props: {
		modelValue: {
			type: Array ,
			default: ()=>[]
		} ,
		originalValue: {
			type: Array ,
			required: false ,
			default: ()=>[]
		} ,
		properties: {
			type: Array ,
			default: () => []
		}
	} ,
	computed: {
		propertyList: function() {
			if ( this.properties.length ) {
				return this.properties.map( propertyName => this.property.of.properties[propertyName] ) ;
			}
			else if ( this.property.of?.properties ) {
				return Object.values( this.property.of.properties ) ;
			}
			return [] ;
		} ,
		getColumnsLength: function() {
			return this.propertyList.length ;
		}
	} ,
	methods: {
		add: function() {
			return this.value.push( this.originalSchema.createWorkingDocument( {} , this.property.of ) ) ;
		} ,
		moveUp: function( index ) {
			this.move( index , index - 1 ) ;
		} ,
		moveDown: function( index ) {
			this.move( index , index + 1 ) ;
		} ,
		move: function( from , to ) {
			this.value.splice( to , 0 , this.value.splice( from , 1 )[0] ) ;
		} ,
		remove: function( index ) {
			this.value.splice( index , 1 ) ;
		}
	}
} ;
</script>

<style scoped>
.embedded {
	--columns: v-bind('getColumnsLength');
}
</style>
