<template>
	<div v-if="ready" class="value backLink">
		<display-link
			v-for="document in collection"
			:key="document._id"
			:property="property"
			:original-value="document"
			v-bind="$attrs"
		/>
	</div>
</template>

<script>
import displayLink from './link.vue' ;

import SetupCollection from '@/libRestQuery/vue/setups/Collection.js' ;

import mixin from './mixin' ;
import { inject } from 'vue' ;

export default {
	components: {
		displayLink
	} ,
	mixins: [mixin] ,
	setup( props , context ) {
		// FIXME: when in collection, there is no provided documents
		var originalDocument = inject( 'document' ) ;
		var id = originalDocument.value?._id ;

		if ( props.property.anyCollection ) {
			id += `@${originalDocument.value._collection}` ;
		}

		return SetupCollection( props , context , {
			collection: props.property.collection ,
			query: {
				filters: {
					[props.property.path]: {
						'$in': [id]
					}
				}
			} ,
			queryStats: false
		} ) ;
	}
} ;
</script>