<template>
	<div class="document">
		<h1>{{ i18n('users.title') }}</h1>
		<section>
			<header>{{ i18n('users.title') }}</header>

			<div class="grid-align">
				<rq-property v-model="value['name']" v-bind="auto('name')" />
				<rq-property v-model="value['email']" v-bind="auto('email')" />
				<rq-property v-if="edit" v-model="value['passwordInput']" v-bind="auto('passwordInput')" />
				<rq-property v-model="value['creationDate']" v-bind="auto('creationDate')" />
				<rq-property v-model="value['lastVisit']" v-bind="auto('lastVisit')" />
				<rq-property v-model="value['archived']" v-bind="auto('archived')" />
			</div>
		</section>
	</div>
</template>

<script>
import mixin from './mixin.js' ;

export default {
	mixins: [mixin]
} ;
</script>
