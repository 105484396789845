<template>
	<div v-if="edit" class="actions">
		<div class="actions-start">
			<Button-icon class="action" icon="undo" :text="i18n('go_back')" @click="action('switchEdit')" />
		</div>
		<Button-icon class="action ok" icon="save" :text="i18n('save')" @click="action('saveAndDisableEdit')" />
	</div>
	<div v-else class="actions">
		<div class="actions-start">
			<Button-icon class="action" icon="grid_view" :text="i18n('back_collection')" :to="navigation.back" />
		</div>
		<Button-icon class="action" icon="place_item" :text="i18n('deposits')" :to="createFrom('deposits')" />
		<Button-icon class="action" icon="local_shipping" :text="i18n('return')" :to="createFrom('returns')" />
		<Button-icon class="action" icon="request_quote" :text="i18n('invoice')" :to="createFrom('invoices')" />


		<Button-icon v-if="document._frozen" class="action" icon="lock" :text="i18n('unlock')" @click="action('unfreeze')" />
		<template v-else>
			<Button-icon class="action" icon="edit" :text="i18n('edit')" @click="action('switchEdit')" />
			<Button-icon v-if="schema.schema.freezable" class="action" icon="lock_open_right" :text="i18n('lock')" @click="action('freeze')" />
		</template>
	</div>
</template>

<script>
import Navigation from '@/stores/Navigation.js' ;

export default {
	props: {
		document: {
			type: Object ,
			default: () => {}
		} ,
		schema: {
			type: Object ,
			default: () => {}
		} ,
		edit: {
			type: Boolean ,
			default: false
		}
	} ,
	emits: ['action:exec'] ,
	data: function() {
		return {
			navigation: Navigation()

		} ;
	} ,
	methods: {
		action: function( name , args ) {
			this.$emit( `action:exec` , name , args ) ;
		} ,
		// FIXME: this function exist in mixin
		createFrom: function( collection ) {
			if ( ! this.document ) return {} ;
			return {
				params: {
					collection: collection ,
					document: 'NEW'
				} ,
				query: {
					method: 'EDIT' ,
					baseDocument: JSON.stringify( { client: this.document._id } )
				}
			} ;
		}
	}
} ;
</script>

<style scoped src="../css/actions.css" />
