<template>
	<input
		v-model="value"
		:name="property.name"
		type="date"
		class="value"
		:required="required"
		:disabled="disabled"
		:min="property.min"
		:max="property.max"
		:step="step"
	/>
</template>

<script>
/* FIXME:
Should accept those types (need backend integration)
date, month, week, time, datetime-local
*/
import dates from '@/libRestQuery/js/utils/dates.js' ;
import mixin from './mixin' ;

export default {
	mixins: [mixin] ,
	props: {
		step: {
			type: String ,
			default: 'any'
		}
	} ,
	computed: {
		value: {
			get() {
				return dates.dateUTC( this.modelValue ) ;
			} ,
			set( value ) {
				this.emitValue( value ) ;
			}
		}
	} ,
	mounted: function() {
		if ( ! this.modelValue && this.property.defaultFn === 'now' ) {
			this.value = dates.dateUTC( new Date() ) ;
		}
	}
} ;
</script>
