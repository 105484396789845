<template>
	<div v-if="ready" class="value backLink">
		<router-link v-for="document in collection" :key="document._id" class="document" :to="link(document)">
			<slot :schema="schema" :document="document">
				<rq-property
					v-for="property in propertyList"
					:key="property.name"
					:property="property"
					:original-value="document[property.name]"
				/>
			</slot>
		</router-link>
	</div>
</template>

<script>
import strings from '@/libRestQuery/js/utils/strings.js' ;

import SetupCollection from '@/libRestQuery/vue/setups/Collection.js' ;

import mixin from './mixin' ;
import { inject } from 'vue' ;

export default {
	mixins: [mixin] ,
	props: {
		properties: {
			type: Array ,
			default: () => ['hid']
		}
	} ,
	setup( props , context ) {
		// FIXME: when in collection, there is no provided documents
		var originalDocument = inject( 'document' ) ;
		var id = originalDocument.value?._id ;

		if ( props.property.anyCollection ) {
			id += `@${originalDocument.value._collection}` ;
		}

		return SetupCollection( props , context , {
			collection: props.property.collection ,
			query: {
				filters: {
					[props.property.path]: {
						'$in': [id]
					}
				}
			} ,
			queryStats: false
		} ) ;
	} ,
	computed: {
		propertyList: function() {
			return this.properties.map( property => this.schema.properties[property] ) ;
		}
	} ,
	methods: {
		link: function( document ) {
			return {
				params: {
					collection: strings.capitalize( this.collectionName ) ,
					document: document.slugId || document._id
				}
			} ;
		}
	}
} ;
</script>