<template>
	<div class="Collection">
		<div v-if="ready" class="content">
			<div v-for="document in collection" :key="document._id">
				<slot :store="store" :schema="schema" :document="document">
					{{ document._collection }}
					<router-link :key="document._id" class="documentss" :to="{params: {collection:document._collection , document: document.slugId}}">
						<div>{{ document.hid }}</div>
					</router-link>
				</slot>
			</div>
		</div>
	</div>
</template>

<script>
import SetupCollection from '@/libRestQuery/vue/setups/Collection.js' ;

export default {
	props: {
		restQuery: {
			type: Object ,
			required: true
		}
	} ,
	setup: SetupCollection
} ;
</script>
