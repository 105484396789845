<template>
	<h1>Semaine {{ document.collectingWeek || '??' }}</h1>
	<rq-property v-bind="auto('state')" />
	<rq-property v-bind="auto('shippingNumber')" />
	<rq-property v-bind="auto('deposit')" />
</template>

<script>
import SetupDocument from '@/libRestQuery/vue/setups/Document.js' ;
import mixin from './mixin.js' ;

export default {
	mixins: [mixin] ,
	setup: SetupDocument
} ;
</script>