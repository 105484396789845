<template>
	<div>
		<Document v-if="$route.params.document" :key="$route.params.document+$route.query.method" :edit="edit" :rest-query="restQuery" />
		<Collection v-else :key="$route.params.collection" :rest-query="restQuery" />
	</div>
</template>

<script>
import CollectionLib from '@/libRestQuery/js/Collection.js' ;

import Collection from './Collection.vue' ;
import Document from './Document.vue' ;

export default {
	components: {
		Collection ,
		Document
	} ,
	computed: {
		edit: function() {
			return this.$route.query.method === 'EDIT' ;
		} ,
		restQuery: function() {
			var restQuery = {
				collection: this.$route.params.collection ,
				document: this.$route.params.document || null
			} ;

			if ( ! restQuery.document ) {
				restQuery.query = Object.assign( {} , {
					sortName: '_id' ,
					sortOrder: '-1' ,
					limit: 30
				} , CollectionLib.unflatQuery( this.$route.query ) ) ;
			}

			return restQuery ;
		}
	}
} ;
</script>
