<template>
	<Teleport to="body">
		<div v-if="visible" class="debug">
			<button @click="visible=false">Close</button>

			<div v-for="timer of getTimers()" :key="timer.name">
				{{ timer.name }} -
				{{ timer.samples }} samples -
				Avg: {{ timer.average }}ms -
				Total: {{ timer.total }}ms -
				Slowest: {{ timer.slowest }}ms -
				Quickest: {{ timer.quickest }}ms
			</div>

			<div class="content">
				<div class="row head">
					<div>name</div>
					<div>localName</div>
					<div>type</div>
					<div>indexed</div>
					<div>optional</div>
					<div>default</div>
					<div v-if="document">value</div>
				</div>
				<div v-for="property in schema" :key="property.name" class="row body">
					<div>{{ property.name }}</div>
					<div>{{ property.name !== property.localName ? property.localName : '' }}</div>
					<div>{{ property.type }}</div>
					<div>{{ indexed[property.name]?true:false }}</div>
					<div>{{ property.required ? 'required' : '' }}</div>
					<div>{{ property.default }}</div>
					<div v-if="document" class="value">{{ document[property.name] }}</div>
					<!-- <pre v-if="document">{{ formatValue(document[property.name]) }}</pre> -->
				</div>
			</div>
		</div>
	</Teleport>
</template>

<script>
import Collections from '@/libRestQuery/vue/store/Collections/index.js' ;
import { Timers } from '@/libRestQuery/js/Timers.js' ;

export default {
	data: function() {
		return {
			visible: false ,
			schema: {} ,
			document: {}
		} ;
	} ,
	mounted: function() {
		document.addEventListener( 'debug' , () => this.show() ) ;
		document.addEventListener( 'keydown' , event => {
			if ( event.ctrlKey && event.altKey && event.key === 'd' ) {
				this.show() ;
			}
		} ) ;
	} ,
	methods: {
		formatValue: function( value ) {
			return typeof value === 'object' ? null : value ;
			// return Array.isArray( value ) ? null : value ;
		} ,
		getTimers: function() {
			return Timers.getAll() ;
		} ,
		show: function() {
			let collectionName = this.$route.params.collection ,
				documentName = this.$route.params.document ;

			if ( this.visible || ! collectionName ) return this.visible = false ;

			const store = Collections( collectionName ) ;

			this.schema = store.schema.contents ;
			this.indexed = store.schema.indexes ;
			this.document = documentName ? store.document( documentName ) : {} ;
			this.visible = true ;

			return true ;
		}
	}
} ;
</script>

<style scoped>
.debug {
	position: fixed;
	background-color: black;
	color: white;
	z-index: 999;
	top: 10%;
	bottom: 10%;
	left: 10%;
	right: 10%;
	overflow: auto;
	padding: 1em;
	font-size: 1.2em;

	button {
		position: sticky;
		top: 0;
		left: 100%;
		z-index: 2;
	}

	.content {
		display: grid;
		width: 100%;
		overflow: clip;

		.row {
			grid-auto-flow: column;
			display: grid;
			grid-template-columns: subgrid;
			grid-column: 1 / span 7;

			>* {
				padding: 0.2em 0.4em;
			}

		}

		.body.row {
			box-shadow: 0 0 0 1px #333 inset;
		}

		.body.row:nth-child(odd) {
			background-color: #333;
		}

		.head {
			background-color: #666 !important;
			position: sticky;
			top: 0;
		}

		.value {
			text-wrap: nowrap;
		}
	}
}
</style>