export default function I18n() {
	this.dictionnary = new Map() ;
	return this ;
}

I18n.prototype.addDictionnaries = function( dictionnaries , namespace ) {
	for( let [prefix , value] of Object.entries( dictionnaries ) ) {
		this.addDictionnary( value , namespace , prefix ) ;
	}
	return this ;
} ;

I18n.prototype.addDictionnary = function( dictionnary , namespace , prefix ) {
	for( let [key , value] of Object.entries( dictionnary ) ) {
		let fullkey = prefix === 'shared' ? `${namespace}.${key}` : `${namespace}.${prefix}.${key}` ;
		this.dictionnary.set( fullkey.toLowerCase() , value ) ;
	}
	return this ;
} ;

I18n.prototype.get = function( key ) {
	// FIXME BAD, the whole shit
	key = key.toLowerCase() ;
	if ( this.dictionnary.has( key ) ) {
		let bob = this.dictionnary.get( key ) ;
		if ( bob === '#' ) {
			bob = key.split( '.' ).pop() ;
		}
		return bob ;
	}

	// FIXME: maybe
	let altkey = key.split( '.' ).toSpliced( 1 , 1 ).join( '.' ) ;
	if ( this.dictionnary.has( altkey ) ) {
		return this.dictionnary.get( altkey ) ;
	}

	console.log( 'I18N_key_not_found:' , key ) ;
	return key.split( '.' ).pop() ;
} ;