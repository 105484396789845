<template>
	<div class="document">
		<h1>{{ i18n('carousels.title') }}</h1>
		<div class="cols2">
			<section v-grid-area:identification>
				<header>{{ i18n('title.identification') }}</header>
				<div class="grid-align">
					<rq-property v-model="value['name']" v-bind="auto('name')" />
					<rq-property v-model="value['season']" v-bind="auto('season')" />
					<rq-property v-model="value['year']" v-bind="auto('year')" />
					<rq-property v-model="value['rows']" v-bind="auto('rows')" />
					<rq-property v-model="value['archived']" v-bind="auto('archived')" />
				</div>
			</section>
			<section v-grid-area:composition>
				<header>{{ i18n('carousels.composition') }}</header>
				<rq-property v-model="value['products']" v-bind="auto('products',{label:false})" />
			</section>
		</div>

		<MetaBlock />
	</div>
</template>

<script>
import mixin from './mixin.js' ;

export default {
	mixins: [mixin]
} ;
</script>

<style scoped>
.document {
	grid-template:
		"identification metas"
		"composition composition" / 1fr 1fr;
}
@media (max-width: 1024px) {
	.document {
		grid-template:
			"identification"
			"metas"
			"composition" / 1fr;
	}
}
</style>

