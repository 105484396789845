<template>
	<h1>{{ document.client?.hid || 'No client' }}</h1>
	<rq-property v-bind="auto('state')" />
	<rq-property v-bind="auto('orderNumber')" />
	<rq-property v-bind="auto('departureDate')" />
	<div>En dépôt : {{ total }} unitées</div>
	<div>Durée : {{ daysSinceDeposits }} jours</div>
</template>

<script>
import SetupDocument from '@/libRestQuery/vue/setups/Document.js' ;
import mixin from './mixin.js' ;

export default {
	mixins: [mixin] ,
	setup: SetupDocument ,
	computed: {
		total: function() {
			return this.document.products.reduce( ( acc , product ) => acc + product.quantity || 0 , 0 ) ;
		} ,
		daysSinceDeposits: function() {
			const date1 = Date.now() ;
			const date2 = new Date( this.document.orderDate ) ;

			const diffTime = Math.abs( date2 - date1 ) ;
			return Math.floor( diffTime / ( 1000 * 60 * 60 * 24 ) ) ;
		}
	}
} ;
</script>