<template>
	<div class="Statistics">
		<form class="Query center" @submit.prevent>
			<div class="flex place-items-center">
				<select v-model="query.method">
					<option value="INVOICES-STATISTICS">Selon facture</option>
					<option value="DEPOSITS-STATISTICS">Selon dépôt</option>
				</select>
				<select v-model="query.method">
					<option value="">Par produits</option>
					<option value="">Par présentoire</option>
				</select>


				<input
					v-model="query.params['params.start']"
					type="date"
					label="Du"
					min="2000-01-01"
				/>
				<input
					v-model="query.params['params.end']"
					type="date"
					label="au"
					min="2000-01-01"
				/>
			</div>
		</form>

		<div v-if="invoicesStats && query.method==='INVOICES-STATISTICS'" class="document invoices-statistics">
			<h1>INVOICES</h1>
			<div class="bilan">
				<div>excludingTaxAmount : {{ toCurrency(invoicesStats.excludingTaxAmount?.toFixed(2)) }}</div>
				<div>includingTaxAmount : {{ toCurrency(invoicesStats.includingTaxAmount?.toFixed(2)) }}</div>
				<div>amountPaid : {{ toCurrency(invoicesStats.amountPaid?.toFixed(2)) }}</div>
				<div>priceSum : {{ toCurrency(invoicesStats.priceSum?.toFixed(2)) }}</div>
			</div>


			<div class="property display-table">
				<table class="table">
					<tbody>
						<tr>
							<th>Nom</th>
							<th class="number">Vendu</th>
							<th class="number">Résultat</th>
						</tr>
						<tr v-for="product in invoicesSumary" :key="product.shortTypeCode" class="document" :class="product.class">
							<td>{{ product.name }}</td>
							<td class="number">{{ toNumber(product.sold) }}</td>
							<td class="number">{{ toCurrency(product.price) }}</td>
						</tr>
					</tbody>
				</table>
			</div>
		</div>

		<div v-else-if="depositsStats && query.method==='DEPOSITS-STATISTICS'" class="document deposits-statistics">
			<h1>DEPOSITS</h1>

			<div class="property display-table">
				<table class="table">
					<tbody>
						<tr>
							<th>Nom</th>
							<th class="number">Implanté</th>
						</tr>
						<tr v-for="product in depositsSumary" :key="product.shortTypeCode" class="document" :class="product.class">
							<td>{{ product.name }}</td>
							<td class="number">{{ toNumber(product.depositQuantity) }}</td>
						</tr>
					</tbody>
				</table>
			</div>
		</div>

		<div v-else class="contentPlaceholder">
			<span class="material-symbols loader">progress_activity</span>
		</div>
	</div>
</template>

<script>

import Collections from '@/libRestQuery/vue/store/Collections/index.js' ;
import Navigation from '@/stores/Navigation.js' ;

export default {
	setup: function() {
		Navigation().addEntry( {
			path: '/Statistics' ,
			title: 'Statistiques' ,
			icon: 'bar_chart'
		} ) ;
		return {
			store: Collections( 'Organizations' )
		} ;
	} ,
	data: function() {
		return {
			invoicesStats: null ,
			invoicesSumary: [] ,

			depositsStats: null ,
			depositsSumary: [] ,
			query: {
				method: 'INVOICES-STATISTICS' ,
				limit: null ,
				params: {
					'params.start': '2023-01-01' ,
					'params.end': '2024-01-01'
				}
			}
		} ;
	} ,
	watch: {
		query: {
			handler: function() {
				this.getStats() ;
			} ,
			immediate: true ,
			deep: true
		}
	} ,
	methods: {
		getStats: function() {
			this.store.restQueryCollection.get( this.query )
				.then( stats => {
					if ( this.query.method === 'INVOICES-STATISTICS' ) this.setInvoices( stats ) ;
					if ( this.query.method === 'DEPOSITS-STATISTICS' ) this.setDeposits( stats ) ;
				} ) ;
		} ,
		setInvoices: function( stats ) {
			this.invoicesStats = stats ;
			var sumary = {} ;

			for( let [name , product] of Object.entries( stats.products ) ) {
				sumary[product.type] ??= {
					name: `Total > ${product.type}` ,
					sold: 0 ,
					price: 0 ,
					class: 'total'
				} ;
				sumary[product.type].sold += product.sold || 0 ;
				sumary[product.type].price += product.price || 0 ;

				sumary[product.shortTypeCode] ??= {
					name: `Total > ${product.type} > ${product.subtype}` ,
					sold: 0 ,
					price: 0 ,
					class: 'subTotal'
				} ;
				sumary[product.shortTypeCode].sold += product.sold || 0 ;
				sumary[product.shortTypeCode].price += product.price || 0 ;

				sumary[name] = {
					name: `${product.shortTypeCode} ${product.name}` ,
					sold: product.sold || 0 ,
					price: product.price || 0 ,
					class: ''
				} ;
			}

			this.invoicesSumary = Object.values( sumary ).sort( ( a , b ) => a.name > b.name ) ;
		} ,
		setDeposits: function( stats ) {
			this.depositsStats = stats ;
			var sumary = {} ;

			for( let [name , product] of Object.entries( stats.products ) ) {
				sumary[product.type] ??= {
					name: `0-Total > ${product.type}` ,
					depositQuantity: 0 ,
					class: 'total' ,
					order: `${product.shortTypeCode}`
				} ;
				sumary[product.type].depositQuantity += product.depositQuantity || 0 ;

				sumary[product.shortTypeCode] ??= {
					name: `0-Total > ${product.type} > ${product.subtype}` ,
					depositQuantity: 0 ,
					class: 'subTotal' ,
					order: `${product.shortTypeCode}`
				} ;
				sumary[product.shortTypeCode].depositQuantity += product.depositQuantity || 0 ;

				sumary[name] = {
					name: `${product.shortTypeCode} ${product.name}` ,
					depositQuantity: product.depositQuantity || 0 ,
					class: '' ,
					order: product.shortTypeCode ,
					sellingPrice: product.sellingPrice
				} ;
			}

			this.depositsSumary = Object.values( sumary ).sort( ( a , b ) => a.order > b.order ).sort( ( a , b ) => a.sellingPrice > b.sellingPrice ) ;
		} ,

		toCurrency: function( number ) {
			return new Intl.NumberFormat( 'fr-FR' , { style: 'currency' , currency: 'EUR' } ).format( number ) ;
		} ,
		toNumber: function( number ) {
			return new Intl.NumberFormat().format( number ) ;
		}
	}
} ;
</script>

<style src="./css/query.css" />


<style scoped>
.Statistics {
	margin: 2em auto 8em;
}
.number{
	text-align:right;
}
.document {
	width: 80%;
	margin: 2em auto;
	padding:2em;
	box-sizing: border-box;

    background-color: var(--color-background);
    border-radius: 1.2em 1.2em 1em 1em;
    border-top: none;
}

.table{

	margin:auto ;
	margin-top:1em;
	border-spacing: 0px;
	width:100%;



	th{
		font-size: 0.9em;
		font-weight: 600;
		padding: 0 0.5em;

		}

	td {
		padding:0 0.5em;
	}

	tr{
		border:solid 1px red;
		border-radius:0em;
	}

	tr.total{
		background-color: #00000050;
		td{
			padding:0.5em;
			font-weight:bold;

		}
	}
	tr.subTotal{
		background-color: #00000030;
	}

}


</style>
