<template>
	<div class="Collection">
		<component :is="filterComponent" v-if="schema" v-model="query" />
		<!-- <component :is="filterComponent" v-if="schema" v-model="query" :filters="['client']" /> -->

		<component :is="actionComponent" @action:exec="action" />

		<div v-if="ready && collection.length" class="content">
			<component
				:is="selectMode?'div':'router-link'"
				v-for="document in collection"
				:key="document._id"
				class="document"
				:class="{selected:selected.has(document._id)}"
				:to="{params: {document: document.slugId}}"
				@click="toggleSelected(document._id)"
			>
				<component
					:is="collectionComponent"
					:rest-query="{
						collection: restQuery.collection ,
						document: document._id
					}"
				/>
			</component>
		</div>
		<div v-else-if="ready && ! collection.length" class="contentPlaceholder">
			<span class="material-symbols">data_array</span>
			{{ i18n('no_data') }}
		</div>
		<div v-else class="contentPlaceholder">
			<span class="material-symbols loader">progress_activity</span>
		</div>

		<Pagination v-if="collectionMeta" v-model="query" :collection-meta="collectionMeta" />
	</div>
</template>

<script>
import importGlobToObject from '@/libRestQuery/js/utils/importGlobToObject.js' ;
import Collection from '@/libRestQuery/js/Collection.js' ;
import SetupCollection from '@/libRestQuery/vue/setups/Collection.js' ;
import Pagination from '@/libRestQuery/vue/components/Pagination.vue' ;

import Navigation from '@/stores/Navigation.js' ;

const customCollections = import.meta.glob( './collection/*.vue' , { eager: true } ) ;

export default {
	components: {
		Pagination ,
		...importGlobToObject( customCollections )
	} ,
	props: {
		restQuery: {
			type: Object ,
			required: true
		}
	} ,
	setup: SetupCollection ,
	data: function() {
		return {
			selectMode: false ,
			navigation: Navigation() ,
			collectionComponent: this.$options.components[this.restQuery.collection] ? this.restQuery.collection : 'Default-list' ,
			filterComponent: this.$options.components[`${this.restQuery.collection}-query`] ? `${this.restQuery.collection}-query` : 'Default-query' ,
			actionComponent: this.$options.components[`${this.restQuery.collection}-actions`] ? `${this.restQuery.collection}-actions` : 'Default-actions'
		} ;
	} ,
	watch: {
		query: {
			handler: function() {
				this.$router.push( {
					query: Collection.flatQuery( this.query )
				} ) ;
			} ,
			deep: true
		} ,
		collectionMeta: function() {
			this.addEntry() ;
		}
	} ,
	mounted: function() {
		this.$router.replace( {
			query: Collection.flatQuery( this.query )
		} ) ;
		this.addEntry() ;
	} ,
	methods: {
		action: function( name ) {
			if ( ! this[name] ) {
				console.log( `Action ${name} not found` ) ;
				return false ;
			}
			return this[name].call( this ) ;
		} ,
		exportCsv: function() {
			this.store.collectionMethod( 'EXPORT-CSV' , { method: 'GET' } )
				.then( content => this.downloadFile( content ) ) ;
		} ,
		downloadFile: function( file ) {
			// FIXME: copied from internet, check if ok
			const link = document.createElement( 'a' ) ;
			link.style.display = 'none' ;
			link.href = window.URL.createObjectURL( new Blob( [file] , { type: 'text/csv' } ) ) ;
			link.download = file.name ;
			link.download = this.collectionName ;

			// It needs to be added to the DOM so it can be clicked
			document.body.appendChild( link ) ;
			link.click() ;

			// To make this work on Firefox we need to wait
			// a little while before removing it.
			setTimeout( () => {
				URL.revokeObjectURL( link.href ) ;
				link.parentNode.removeChild( link ) ;
			} , 0 ) ;
		} ,
		toggleSelectMode: function() {
			this.selectMode = ! this.selectMode ;
		} ,
		batchRemove: function() {
			for( let documentId of this.selected.values() ) {
				// this.store.document( documentId ).remove() ;
				this.store.deleteDocument( documentId ).then( () => {
					this.selected.delete( documentId ) ;
					this.refresh() ;
				} ) ;
			}
		} ,
		toggleSelected: function( id ) {
			if ( this.selected.has( id ) ) {
				this.selected.delete( id ) ;
			}
			else {
				this.selected.add( id ) ;
			}
		} ,
		addEntry: function() {
			this.navigation.addEntry( {
				collection: this.restQuery.collection ,
				document: this.restQuery.document ,

				path: this.$route.fullPath ,
				title: this.store.restQueryCollection.localName ,
				icon: 'grid_view' ,
				number: this.collectionMeta?.count
			} ) ;
		} ,
		generateFake: function() {
			this.store.collectionMethod( 'GENERATE-FAKE' , {
				body: {
					count: 50
				}
			} ) ;
		}
	}
} ;
</script>

<style scoped src="./css/actions.css" />
<style src="./css/query.css" />
<style src="./css/pagination.css" />
<style src="./css/collection.css" />

<style scoped>
.selected {
	box-shadow: 0 0 4px 2px var(--color-focus) !important;
}
</style>