<template>
	<a v-if="hrefValue" class="value text" :href="hrefValue" v-text="value" />
	<pre v-else class="value text" v-text="property.localIn?.[value] || value" />
</template>

<script>
import mixin from './mixin' ;

export default {
	mixins: [mixin] ,
	computed: {
		hrefValue: function() {
			if ( ! this.value ) return false ;
			let value = this.value.replaceAll( ' ' , '' ) ;
			switch( this.property.ui?.type ) {
				case 'email':return `mailto:${value}` ;
				case 'tel':return `tel:${value}` ;
				default: return false ;
			}
		}
	}
} ;
</script>

<style scoped>
pre {
	margin: unset;
	display: inline-block;
}
</style>