<template>
	<div class="document">
		<h1>
			{{ i18n('organizations.title') }}<span v-if="hasOpenPeriods">
				- En dépôt depuis {{ getDurationPeriods }} jours
			</span>
		</h1>
		<div class="cols2">
			<section v-grid-area:identification>
				<header>{{ i18n('title.identification') }}</header>
				<div class="grid-align">
					<rq-property v-model="value['clientCode']" v-bind="auto('clientCode')" />
					<rq-property v-model="value['companyName']" v-bind="auto('companyName')" />
					<rq-property v-model="value['contactType']" v-bind="auto('contactType')" />
					<rq-property v-model="value['clientType']" v-bind="auto('clientType')" />
					<rq-property v-model="value['groupName']" v-bind="auto('groupName')" />
					<rq-property v-model="value['familyName']" v-bind="auto('familyName')" />
					<rq-property v-model="value['organizationName']" v-bind="auto('organizationName')" />
				</div>
			</section>

			<section v-grid-area:metas class="locked">
				<header>{{ i18n('organisations.fastcontact') }}</header>
				<rq-property v-model="value['communications']" v-bind="auto('communications',{label:false,edit:false})" :properties="['phone']" />
			</section>

			<section v-grid-area:info>
				<header>{{ i18n('organization.info') }}</header>

				<div class="grid-align">
					<rq-property v-model="value['reminderDate']" v-bind="auto('reminderDate')" />
					<rq-property v-model="value['commercialRate']" v-bind="auto('commercialRate')" />
					<rq-property v-model="value['cashDesks']" v-bind="auto('cashDesks')" />
					<rq-property v-model="value['commercial']" v-bind="auto('commercial')" />


					<rq-property v-model="value['isCentralized']" v-bind="auto('isCentralized')" />
				</div>
			</section>

			<section v-grid-area:stickers>
				<header>{{ i18n('organization.stickers') }}</header>
				<div>
					<rq-property v-model="value['tags']" link-to="filter" class="as-tags" v-bind="auto('tags',{label:false})" />
				</div>
			</section>

			<section v-grid-area:deposits class="locked">
				<header>
					{{ i18n('organization.deposits') }}
					<router-link class="button" :to="createFrom('deposits')">{{ i18n('btn-add') }}</router-link>
				</header>
				<div>
					<rq-property
						v-bind="auto('deposits',{label:false,edit:false})"
						:properties="[
							'orderNumber',
							'departureDate',
							'deliveryDate',
							'carousels',
							'state'
						]"
					/>
				</div>
			</section>
			<section v-grid-area:returns class="locked">
				<header>
					{{ i18n('organization.returns') }}
					<router-link class="button" :to="createFrom('returns')">{{ i18n('btn-add') }}</router-link>
				</header>
				<div>
					<rq-property
						v-bind="auto('returns',{label:false,edit:false})"
						:properties="[
							'shippingNumber',
							'collectingWeek',
							'carouselCount',
							'packageCount',
							'packaging',
							'state'
						]"
					/>
				</div>
			</section>
			<section v-grid-area:invoices class="locked">
				<header>
					{{ i18n('organization.invoices') }}
					<router-link class="button" :to="createFrom('invoices')">{{ i18n('btn-add') }}</router-link>
				</header>
				<div>
					<rq-property
						v-bind="auto('invoices',{label:false,edit:false})"
						:properties="[
							'invoiceNumber',
							'invoiceDate',
							'excludingTaxAmount',
							'state'
						]"
					/>
				</div>
			</section>

			<section v-grid-area:sales class="locked">
				<header>
					{{ i18n('organization.sales') }}
					<router-link class="button" :to="createFrom('invoices')">{{ i18n('btn-add') }}</router-link>
				</header>
				<div v-if="document?._id">
					SALES
				</div>
			</section>


			<!-- All Notes Types -->
			<template v-for="type in ['commercial','expedition','invoicing']" :key="type">
				<section v-grid-area:invoices class="locked">
					<header>
						{{ type }} {{ i18n('title.comments') }}
						<router-link class="button" :to="createComment(type)">{{ i18n('btn-add') }}</router-link>
					</header>
					<div v-if="document?._id">
						<OrganizationsNotes :type="type" />
					</div>
				</section>
			</template>
		</div>

		<section v-grid-area:addresses>
			<header>{{ i18n('organization.addresses') }}</header>
			<rq-property
				v-model="value['addresses']"
				v-bind="auto('addresses',{label:false})"
				:properties="[
					'type',
					'address',
					'zipCode',
					'city',
					'country'
				]"
			/>
		</section>

		<section v-grid-area:communications>
			<header>{{ i18n('organization.communications') }}</header>
			<rq-property
				v-model="value['communications']"
				v-bind="auto('communications',{label:false})"
				:properties="[
					'type',
					'firstName',
					'lastName',
					'phone',
					'cellphone',
					'fax',
					'email'
				]"
			/>
		</section>

		<MetaBlock />
	</div>
</template>

<script>
import mixin from './mixin.js' ;
import OrganizationsNotes from './Organizations-notes.vue' ;

export default {
	components: {
		OrganizationsNotes
	} ,
	mixins: [mixin] ,
	computed: {
		hasOpenPeriods: function() {
			return !! this.getOpenPeriods?.length ;
		} ,
		getOpenPeriods: function() {
			return this.document?.invoicingPeriods.filter( a => ! a.closed ) ;
		} ,
		getDurationPeriods: function() {
			var startDate = this.getOpenPeriods?.shift().start ;

			return Math.floor( Math.abs( new Date() - new Date( startDate ) ) / ( 1000 * 60 * 60 * 24 ) ) ;
		}
	}
} ;
</script>

<style scoped>
.document {
	grid-template:
		"identification metas"
		"stickers deposits"
		"invoices notes"
		"info ."
		"addresses addresses"
		"communications communications" / 1fr 1fr;
}
.document.edit {
	grid-template:
		"identification metas"
		"stickers deposits"
		"invoices notes"
		"info ."
		"addresses addresses"
		"communications communications" / 1fr 1fr;
}
@media (max-width: 1024px) {
	.document {
		grid-template:
			"identification"
			"metas"
			"stickers"
			"deposits"
			"invoices"
			"notes"
			"info"
			"addresses"
			"communications" / 1fr;
	}
}
</style>