<template>
	<div class="document">
		<h1>{{ i18n('products.title') }}</h1>
		<div class="cols2">
			<section data-name:identification>
				<header>{{ i18n('title.identification') }}</header>
				<div class="grid-align">
					<rq-property v-model="value['type']" v-bind="auto('type')" />
					<rq-property v-model="value['subtype']" v-bind="auto('subtype')" />
					<rq-property v-model="value['shortTypeCode']" v-bind="auto('shortTypeCode')" />
					<rq-property v-model="value['barcode']" v-bind="auto('barcode')" />
					<rq-property v-model="value['purchasePrice']" v-bind="auto('purchasePrice')" />
					<rq-property v-model="value['sellingPrice']" v-bind="auto('sellingPrice')" />
					<rq-property v-model="value['archived']" v-bind="auto('archived')" />
				</div>
			</section>

			<MetaBlock />
		</div>
	</div>
</template>

<script>
import mixin from './mixin.js' ;

export default {
	mixins: [mixin]
} ;
</script>

<style scoped>
.document {
	grid-template:
		"identification metas" / 1fr 1fr;
}
@media (max-width: 1024px) {
	.document {
		grid-template:
			"identification"
			"metas" / 1fr ;
	}
}
</style>