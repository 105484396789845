import Collection from '@/libRestQuery/js/Collection.js' ;
// import Collections from '@/libRestQuery/vue/store/Collections/index.js' ;

import { merge } from 'lodash-es' ;

import { isProxy , toRaw } from 'vue' ;

export default {
	async getSchema( force = false ) {
		return this.restQueryCollection.getSchema( force )
			// .then( schema => this.schema = schema ) ;
			.then( () => this.ready = true ) ;
	} ,

	async getCollection( query = {} ) {
		return this.restQueryCollection.get( query )
			.then( collection => {
				this.storeCollection( query , collection ) ;

				// Try to guess the queryStatistics.count of the collection
				// and save it to spare a subsequent request
				if ( collection.length < query.limit ) {
					this.storeCollectionMeta( query , {
						count: query.skip + collection.length
					} ) ;
				}
				return collection ;
			} )
			.catch( error => {
				console.log( error ) ;
			} ) ;
	} ,

	async getCollectionMeta( query ) {
		let url = Collection.queryToString( query , true ) ;

		return this.restQueryCollection.method( 'QUERY-STATISTICS' , {
			method: 'GET' ,
			queryString: url
		// } ).then( collectionMeta => this.collectionsMeta[url] = collectionMeta ) ;
		} ).then( collectionMeta => this.storeCollectionMeta( query , collectionMeta ) ) ;
	} ,

	/* unused at this time
	/*
	getDiff( id , document , oldDocument ) {
		if ( isProxy( document ) ) document = toRaw( document ) ;
		if ( isProxy( oldDocument ) ) oldDocument = toRaw( oldDocument ) ;

		return this.restQueryCollection.document( id )
			.diff( document , oldDocument ) ;
	} ,
	*/

	async getDocument( idOrSlug ) {
		return this.restQueryCollection.document( idOrSlug ).get()
			.then( document => {
				this.storeDocument( document ) ;
				return document ;
			} )
			.catch( error => {
				console.log( error ) ;
			} ) ;
	} ,

	async createDocument( document ) {
		return this.restQueryCollection.create( document ) ;
		// FIXME: is buggy, created document doesnt show after creation
		// .then( document => this.documents[document._id] = document ) ;
	} ,

	async updateDocument( id , document , oldDocument = {} ) {
		if ( isProxy( document ) ) {
			document = toRaw( document ) ;
		}
		if ( isProxy( oldDocument ) ) {
			oldDocument = toRaw( oldDocument ) ;
		}

		return this.restQueryCollection.document( id )
			.update( document , oldDocument )
			.then( document => this.documents[document._id] = document ) ;
	} ,

	async deleteDocument( id ) {
		return this.restQueryCollection.document( id )
			.delete( this.documents[ id ] )
			.then( () => {
				// FIXME SOMEHOW:
				// If you delete documents, you should delete references to them, so
				// iterate all collections array or filter them out in the getter collection()

				// delete this.documents[ id ] ;
				// delete this.documentsSlugs[id] ;
			} ) ;
	} ,

	async collectionMethod( method , options ) {
		return this.restQueryCollection.method( method , options ) ;
	} ,

	async documentMethod( id , method , options = {} ) {
		return this.restQueryCollection.document( id ).method( method , options ) ;
	} ,

	storeCollectionMeta( query , meta ) {
		let url = Collection.queryToString( query , true ) ;
		this.collectionsMeta[url] = meta ;
	} ,

	storeCollection( query , collection ) {
		var url = Collection.queryToString( query ) ;

		if ( ! collection.length ) {
			this.collections[url] = [] ;
			return ;
		}

		this.storeDocuments( collection ) ;
		this.collections[url] = collection.map( document => document._id ) ;
	} ,
	storeDocument( document ) {
		/*
		for( let property of Object.keys( document ) ) {
			if ( document[property]._collection && document[property]._collection.toLowerCase() !== this.restQueryCollection.name.toLowerCase() ) {
				console.log( this.restQueryCollection.name , document[property]._collection , document[property] ) ;
				const store = Collections( document[property]._collection ) ;
				store.storeDocument( document ) ;

				document[property] = {
					_collection: document._collection ,
					_id: document._id ,
					slugId: document.slugId
				} ;
			}
		}
		*/

		this.documentsHids[ document.hid ] = document._id ;
		this.documentsSlugs[ document.slugId ] = document._id ;
		this.documents[ document._id ] = document ;
	} ,
	storeDocuments( documents ) {
		var newDocuments = {} ,
			newDocumentsSlugs = {} ,
			newDocumentsHids = {} ;

		documents.forEach( document => {
			newDocumentsSlugs[document.slugId] = document._id ;
			newDocumentsHids[document.hid] = document._id ;

			// newDocuments[document._id] = { ...state.documents[document._id] , ...document } ;
			newDocuments[document._id] = merge(	{} ,
				this.documents[document._id] ,
				document
			) ;
		} ) ;

		this.$patch( ( state ) => {
			state.documentsHids = { ...state.documentsHids , ...newDocumentsHids } ;
			state.documentsSlugs = { ...state.documentsSlugs , ...newDocumentsSlugs } ;
			state.documents = { ...state.documents , ...newDocuments } ;
		} ) ;
	}
} ;
