<template>
	<rq-property v-bind="auto('note')" />
	<rq-property v-bind="auto('type')" />
	<rq-property v-bind="auto('user')" />
	<rq-property v-bind="auto('dueDate')" />
	<rq-property v-bind="auto('doneDate')" />
</template>

<script>
import SetupDocument from '@/libRestQuery/vue/setups/Document.js' ;
import mixin from './mixin.js' ;

export default {
	mixins: [mixin] ,
	setup: SetupDocument
} ;
</script>