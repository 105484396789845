<template>
	<div>
		<Breadcrumb />
		<Statistics v-if="$route.params.collection==='Statistics'" :key="'Special'+$route.params.collection" />
		<Document v-else-if="$route.params.document" :key="$route.params.document" class="App" :rest-query="restQuery" />
		<Collection v-else :key="$route.params.collection" :rest-query="restQuery" class="App" />
	</div>
</template>

<script>
import CollectionLib from '@/libRestQuery/js/Collection.js' ;
import Breadcrumb from './Breadcrumb.vue' ;
import Collection from './Collection.vue' ;
import Document from './Document.vue' ;
import Statistics from './Statistics.vue' ;

export default {
	components: {
		Breadcrumb ,
		Collection ,
		Document ,
		Statistics
	} ,
	computed: {
		restQuery: function() {
			var restQuery = {
				collection: this.$route.params.collection ,
				document: this.$route.params.document && this.$route.params.document !== 'NEW' ? this.$route.params.document : null
			} ;

			if ( this.$route.query.baseDocument ) {
				try { restQuery.baseDocument = JSON.parse( this.$route.query.baseDocument ) ; }
				catch { /* empty */ }
			}
			if ( ! restQuery.document ) {
				restQuery.query = Object.assign( {
					sortName: '_id' ,
					sortOrder: '-1' ,
					limit: 30
				} ,	CollectionLib.unflatQuery( this.$route.query )	) ;
			}

			return restQuery ;
		}
	}
} ;
</script>
