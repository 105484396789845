<template>
	<div class="Webpage">
		<article>
			<h1>Diffusion et maintenance de l'application</h1>
			<div>
				Cette solution à été réalisé et paramétré sur mesure pour l'entreprise 123 Bijoux par l'entreprise Soulserv qui en assure la maintenance.

				<ul>
					<li>SAS Soulserv</li>
					<li>SIREN 948 003 900 - RCS Aubenas</li>
					<li>12 rue Auguste Clément</li>
					<li>07600 Vals-les-Bains</li>
					<li>hello@soulserv.net</li>
					<li>06 88 43 42 50</li>
				</ul>

				La solution est hébergé par la société Digital-Ocean.
			</div>
		</article>
		<article>
			<h1>Informations techniques sur l'application</h1>
			<div>
				L'application a été réalisé au moyen des technologies suivantes :
				<ul>
					<li>
						<a href="https://fr.vuejs.org/">Vue.js</a>
						> Framework JavaScript open-source
					</li>
					<li><a href="https://github.com/cronvel/rest-query">RestQuery</a> > Framework javascript open-source (Développé par Soulserv)</li>
					<li><a href="https://www.mongodb.com/">MongoDB</a> > Système de gestion de base de données</li>
				</ul>
			</div>
		</article>
		<article>
			<h1>Conformité de la solution de facturation</h1>
			<div>
				<p>A compter du 1er janvier 2018, les assujettis à la TVA qui enregistrent les règlements de leurs clients au moyen d'un logiciel de comptabilité ou de gestion ou d'un système de caisse, devront utiliser un logiciel conforme répondant à certaines conditions.</p>

				<p>Il s'agit de conditions d'inaltérabilité, de sécurisation, de conservation et d'archivage des données en vue du contrôle de l'administration fiscale.</p>

				<p>A ce titre, ils devront produire soit un certificat délivré par un organisme accrédité, soit une attestation individuelle de l'éditeur conforme au modèle fixé par l'administration.</p>
				SOURCE : <a href="https://www.impots.gouv.fr/actualite/obligation-dutiliser-un-logiciel-de-comptabilite-ou-de-gestion-ou-un-systeme-de-caisse">https://www.impots.gouv.fr/actualite/obligation-dutiliser-un-logiciel-de-comptabilite-ou-de-gestion-ou-un-systeme-de-caisse</a>
				<br />
				<ul>
					<li>
						<a target="_blank" href="pdf/attesation individuel - facturation conforme - volet 1.pdf">Télécharger le Volet 1 : Attestation individuelle fournis par l'éditeur du logiciel</a>
					</li>
					<li>
						<a target="_blank" href="pdf/attesation individuel - facturation conforme - volet 2.pdf">Télécharger le Volet 2 : Partie à remplir par l'entreprise qui utilise le logiciel</a>
					</li>
				</ul>
			</div>
		</article>
		<article>
			<h1>Ajout de nouvelles fonctionnalitées</h1>
			<div>
				<div>
					<h2>
						<span class="material-symbols">
							attachment
						</span> Stockage des médias
					</h2>
					Joindre des JDV ou des documents administratif
				</div>
				<div>
					<h2>
						<span class="material-symbols">
							request_quote
						</span> API Chorus
					</h2>
					Synchronisation automatique avec Chorus
				</div>
				<div>
					<h2>
						<span class="material-symbols">
							send
						</span> Module marketing/mail
					</h2>
					Facturation et relance automatique
					Emailing marketing
				</div>
				<div>
					<h2>
						<span class="material-symbols">
							<span class="material-symbols-outlined">
								analytics
							</span>
						</span> Module statistique
					</h2>
					Analyse des tags / Prévisionnel d’achat
				</div>
				<div>
					<h2>
						<span class="material-symbols">
							group
						</span> Module RH
					</h2>
					Pointeuse / Gestion des congés
				</div><div>
					<h2>
						<span class="material-symbols">
							<span class="material-symbols-outlined">
								barcode
							</span>
						</span> Module Logistique
					</h2>
					Scanner - Expédition / Réception des livraisons
				</div><div>
					<h2>
						<span class="material-symbols">
							<span class="material-symbols-outlined">
								wifi_off

							</span>
						</span> Module Offline
					</h2>
					Travailler sur la base sans connection internet
				</div>
			</div>
		</article>
	</div>
</template>

<script>


</script>

<style scoped>

</style>