<template>
	<rq-document :rest-query="restQuery" :edit="edit" />
</template>

<script>
export default {
	props: {
		restQuery: {
			type: Object ,
			required: true
		} ,
		edit: {
			type: Boolean ,
			default: false
		}
	}
} ;
</script>
